import React from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Image from "../components/Image"
import HeroHome from "../components/HeroHome"
import Section from "../components/Section"
import { Container, Row, Column } from "../components/Grid"
import CalltoAction from "../components/CalltoAction"

import ThreeColumnCallout from "../components/ThreeColumnCallout"
import BeVapeFree from "../images/theme/img-hp-be-vape-free.jpg"
import BodyClassName from "react-body-classname"

const IndexPage = () => (
  <BodyClassName className="homepage">
    <Layout>
      <SEO title="Home" />
      {/***************  HERO ***************/}
      <Section className="hero__home">
        <HeroHome />
      </Section>
      {/***************  SIGN UP FOR UPDATES ***************/}
      <Section className="homepage__callout offwhite">
        <CalltoAction to="/heroes-vft" withIcon>
          Watch Anti-Vaping Heroes in Action in the NEW Virtual Field Trip
        </CalltoAction>
      </Section>
      {/***************  HOMEPAGE THREE COLUMN CALLOUTS ***************/}
      <Section className="homepage__threecolcallout offwhite">
        <Container>
          <Row>
            <Column span={4}>
              <ThreeColumnCallout
                label="Educators"
                image="img-hp-colcallout-educators.jpg"
                description={
                  <p>
                    With the CATCH My Breath evidence-based and
                    standards-aligned classroom activities, educators have
                    access to the best in preventative resources.
                  </p>
                }
                to="/educators"
                link="Educator Resources ›"
              />
            </Column>
            <Column span={4}>
              <ThreeColumnCallout
                label="Students"
                image="img-hp-colcallout-students.jpg"
                description={
                  <p>
                    Power your life-long health and well-being with self-led
                    modules focused on refusal skills and the risks of
                    e-cigarettes and vaping.
                  </p>
                }
                to="/students"
                link="Student Resources ›"
              />
            </Column>
            <Column span={4}>
              <ThreeColumnCallout
                label="Parents and Community"
                image="img-hp-colcallout-parents.jpg"
                description={
                  <p>
                    Learn the statistics and facts for the major health risks of
                    vaping and start the conversation at home today.
                  </p>
                }
                to="/parents-and-community"
                link="Parent Resources ›"
              />
            </Column>
          </Row>
        </Container>
      </Section>

      {/*****************  VIRTUAL FIELD TRIP PROMO ***************/}
      <Section>
        <Container>
          <h2 className="serif">Virtual Field Trip Series</h2>
          <Row>
            <Column span="6">
              <Image filename="poster/img-vft-still-2022.jpg" />
            </Column>
            <Column span="6">
              <h2>Be Vape Free: Heroes</h2>
              <p>
                In the fight against unhealthy habits like e-cigarettes, heroes come in all shapes and sizes! Teach students about the dangers of vaping with the help of dedicated subject matter experts and teen advocates in this empowering Virtual Field Trip. Students will find out how social media and other factors can influence their decision-making about vaping, while gaining practical strategies to overcome peer pressure and tools to help those who are addicted find the courage and support to quit.
              </p>
              <p>
                <a href="/heroes-vft" className="green__link">
                  Watch Anti-Vaping Heroes in Action! ›
                </a>
              </p>
            </Column>
          </Row>
        </Container>
      </Section>

      {/***************  ABOUT BE VAPE FREE ***************/}
      <Section className="about__bevapefree lightgray">
        <Container>
          <Row>
            <Column span={8}>
              <h2>About Be Vape Free</h2>
              <p>
                The <strong>Be Vape Free</strong> initiative is a confluence of
                like-minded organizations who have joined forces to tackle the
                use of e-cigarettes among our nation’s youth through the
                creation and distribution of engaging content for grades 5–12 on
                the risks of e-cigarettes. By leveraging partner resources and
                working together as a collaborative initiative, we hope to keep
                students informed on the long-term health risks of vaping. Join
                our mission to provide educators and pharmacists with the tools
                to keep students informed in the classroom, and the
                evidence-based programs that will lead students to make healthy,
                smart decisions for life.
              </p>
            </Column>
            <Column span={4}>
              <img src={BeVapeFree} alt="Be Vape Free" />
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  </BodyClassName>
)

export default IndexPage
