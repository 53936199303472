import VapeFreeVideo from "../../docs/CVS-Be-Vape-Free-Loop-FINAL.mp4"
import React from "react"
import HeroCVSLogo from "../../images/theme/img-hp-bvf-animated.gif"
import "./styles.scss"

const HeroHome = () => (
  <div className="hero__container">
    <div className="hero__branding">
      <img src={HeroCVSLogo} alt="CVS Health" className="hero__logo" />
    </div>
    <div className="hero__video">
      <div className="hero__vidoverlay"></div>
      <video
        className="hero__vidsource"
        autoPlay
        muted
        playsInline
        loop
        controls={false}
      >
        <source src={VapeFreeVideo} type="video/mp4" />
      </video>
    </div>
  </div>
)

export default HeroHome
