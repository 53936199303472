import React from "react"
import PropTypes from "prop-types"
import { Container } from "../Grid"
import { Link } from "gatsby";
import Icon from "../Icon"
import "./styles.scss"

const CalltoAction = ({ children, to }) => (
  <Container>
    <Link to={to} className="cta">
      {children}
      <Icon name="rightarrow" />
    </Link>
  </Container>
)

CalltoAction.defaultProps = {
  withIcon: false,
  className: null,
}

CalltoAction.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  withIcon: PropTypes.bool,
  className: PropTypes.string,
}

export default CalltoAction
