import Image from "../Image"
import PropTypes from "prop-types"
import React from "react"
import "./styles.scss"

const ThreeColumnCallout = ({
  image,
  label,
  description,
  to,
  link,
  comingsoon,
}) => {
  const renderImage = () => {
    return <div className="colcallout__image">{<Image filename={image} />}</div>
  }

  const renderContent = (
    <div className="colcallout__content">
      <h3 className="colcallout__title">{label}</h3>
      <div className="colcallout__description">{description}</div>
      <a href={to} className="colcallout__link">
        {link}
      </a>
      <p className="coming__soon">{comingsoon}</p>
    </div>
  )

  return (
    <div className="colcallout colcallout--vertical">
      {renderImage()}
      {renderContent}
    </div>
  )
}

ThreeColumnCallout.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.object,
  to: PropTypes.string,
  link: PropTypes.string,
  comingsoon: PropTypes.string,
}

export default ThreeColumnCallout
